import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useLoading } from "../../hooks/useLoading";
import { useNotification } from "../../hooks/useNotification";
import axios from "../../services/instance";
import {
  Paper,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Box,
  Alert,
  Stack,
} from "@mui/material";

const DEPARTMENT_OPTIONS = [
  { label: "SCCT", value: 0 },
  { label: "SCM", value: 1 },
  { label: "Softo", value: 2 },
  { label: "TG", value: 3 },
  { label: "HQ", value: 4 },
  { label: "Other", value: 5 },
];

const LEAVE_REASON_OPTIONS = [
  { label: "Плановый отпуск", value: 0 },
  { label: "Личные обстоятельства", value: 1 },
  { label: "Другое", value: 2 },
];

const DAY_PART_OPTIONS = [
  { label: "AM", value: 0 },
  { label: "PM", value: 1 },
  { label: "Full day", value: 2 },
];

const PLANNED_VACATION_REASON = 0;
const OTHER_LEAVE_REASON = 2;

const NUMERIC_FIELDS = ["leaveReason", "dayPart"];

const ALERT_STYLE = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
};

const INITIAL_VACATION_STATE = {
  start: "",
  end: "",
  leaveReason: "",
  leaveReasonOther: "",
  dayPart: "",
  comment: "",
};

const VacationForm = () => {
  const { toggleLoading } = useLoading();
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useNotification();
  const [remainingDays, setRemainingDays] = useState({});
  const [vacation, setVacation] = useState(INITIAL_VACATION_STATE);
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    start,
    end,
    leaveReason,
    leaveReasonOther,
    dayPart,
    comment,
  } = vacation;
  const { prevYearLeft, totalYearLeft } = remainingDays;

  useEffect(() => {
    const fetchRemainingDays = async () => {
      toggleLoading();
      try {
        const { data } = await axios.get("/vacations/remaining_days");
        setRemainingDays(data);
      } catch (error) {
        console.error(error);
      } finally {
        toggleLoading();
      }
    };
    fetchRemainingDays();
  }, []); // Call only once

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    const updatedValue = NUMERIC_FIELDS.includes(name) ? Number(value) : value;
    setVacation((prev) => ({ ...prev, [name]: updatedValue }));
  }, []);

  useEffect(() => {
    const validateDate = () => {
      const error =
        start > end ? "Дата конца должна быть позже даты начала" : null;
      setErrors((prev) => ({ ...prev, end: error }));
    };
    validateDate();
  }, [start, end]);

  const hasErrors = useMemo(
    () => Object.values(errors).some(Boolean),
    [errors]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (hasErrors) return;

      toggleLoading();
      try {
        await axios.post("/vacations", vacation);
        setVacation(INITIAL_VACATION_STATE); // Reset form
        setShowSuccess(true); // Show success message
        setErrors({}); // Clear any existing errors
      } catch (error) {
        setNotification({
          message: "Failed to submit vacation request.",
          type: "error",
        });
        console.error(error);
      } finally {
        toggleLoading();
      }
    },
    [vacation, hasErrors, toggleLoading]
  );

  const handleSuccessClose = useCallback(() => {
    setShowSuccess(false);
  }, []);

  const renderRadioOptions = useCallback(
    (options) =>
      options.map(({ value, label }) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<Radio required />}
          label={label}
        />
      )),
    []
  );

  const leaveReasonOptions = useMemo(
    () => renderRadioOptions(LEAVE_REASON_OPTIONS),
    [renderRadioOptions]
  );
  const dayPartOptions = useMemo(
    () => renderRadioOptions(DAY_PART_OPTIONS),
    [renderRadioOptions]
  );

  const showDayPart = useMemo(
    () => start && end && start === end,
    [start, end]
  );
  const showComment = useMemo(
    () => leaveReason !== PLANNED_VACATION_REASON,
    [leaveReason]
  );

  const remainingDaysText = useMemo(
    () =>
      remainingDays &&
      (totalYearLeft >= 0 ? (
        <Box>
          📅 В этом году на балансе доступно <b>{totalYearLeft}</b> дней.
        </Box>
      ) : (
        <Box>
          Вы вышли за доступные пределы на <b>{Math.abs(totalYearLeft)}</b>{" "}
          дней.
        </Box>
      )),
    [remainingDays, totalYearLeft]
  );

  return (
    <Paper sx={{ p: 2, height: "max-content" }}>
      {showSuccess && (
        <Alert severity="success" onClose={handleSuccessClose} sx={ALERT_STYLE}>
          Запрос на отпуск успешно отправлен! Ожидайте одобрения от вашего
          руководителя.
        </Alert>
      )}
      <Stack spacing={2}>
        <Typography variant="h6">Vacation / Time Off Request</Typography>
        <Box>
          <Typography>{remainingDaysText}</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormControl fullWidth component="fieldset">
              <FormLabel>Тип отсутствия</FormLabel>
              <RadioGroup
                name="leaveReason"
                value={leaveReason}
                onChange={handleInputChange}
              >
                {leaveReasonOptions}
              </RadioGroup>
            </FormControl>
            {leaveReason === OTHER_LEAVE_REASON && (
              <TextField
                required
                fullWidth
                variant="standard"
                name="leaveReasonOther"
                value={leaveReasonOther}
                onChange={handleInputChange}
              />
            )}
            <TextField
              required
              fullWidth
              variant="standard"
              label="Дата начала отпуска"
              type="date"
              name="start"
              value={start}
              onChange={handleInputChange}
              error={!!errors.start}
              helperText={errors.start}
            />
            <TextField
              required
              fullWidth
              variant="standard"
              label="Дата завершения отпуска"
              type="date"
              name="end"
              value={end}
              onChange={handleInputChange}
              error={!!errors.end}
              helperText={errors.end}
            />
            {showDayPart && (
              <FormControl fullWidth component="fieldset" variant="standard">
                <FormLabel>Время отсутствия (*если отпуск 1 день)</FormLabel>
                <RadioGroup
                  row
                  name="dayPart"
                  value={dayPart}
                  onChange={handleInputChange}
                >
                  {dayPartOptions}
                </RadioGroup>
              </FormControl>
            )}
            {showComment && (
              <TextField
                fullWidth
                variant="standard"
                label="Причины отсутствия и другие комментарии"
                name="comment"
                value={comment}
                onChange={handleInputChange}
              />
            )}
            <Button type="submit" variant="contained" color="primary">
              Отправить запрос
            </Button>
          </Stack>
        </form>
      </Stack>
      {notification}
    </Paper>
  );
};

export default VacationForm;
